const Service = ({ services, bookingData, setBookingData }) => {
  const handleChange = (service) => {
    setBookingData((prevState) => ({
      ...prevState,
      bookingDetails: {
        ...prevState.bookingDetails,
        type_of_service: service,
      },
    }));
  };

  return (
    <fieldset className="inputs-wrapper step-wrapper">
      {services?.map((el) => {
        const value = el.service_name.replace(/\srepair$/i, '');
        return (
          <div
            key={value}
            className={`service-wrapper ${
              value === bookingData.bookingDetails.type_of_service ? 'active' : 'inactive'
            }`}
            onClick={() => handleChange(value)}
          >
            <span>{el.service_name}</span>
            <p>{el.service_description}</p>
          </div>
        );
      })}
    </fieldset>
  );
};

export default Service;
