import './preloader.scss';

const Preloader = () => (
  <div className="preloader__wrapper">
    <div className="preloader__inner">
      Loading
      <span />
    </div>
  </div>
);

export default Preloader;
