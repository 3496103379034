import { useState } from 'react';

import './accordion.scss';

const Accordion = ({ title, children }) => {
  const [isOpen, setOpen] = useState(true);

  return (
    <div className="accordion-wrapper">
      <div className={`accordion-title ${isOpen ? 'open' : ''}`} onClick={() => setOpen(!isOpen)}>
        {title}
      </div>
      <div className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>
        <div className="accordion-content">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
