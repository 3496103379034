const Brand = ({ brands, bookingData, setBookingData }) => {
  const handleChange = (service) => {
    setBookingData((prevState) => ({
      ...prevState,
      bookingDetails: {
        ...prevState.bookingDetails,
        brand_name: service,
      },
    }));
  };

  return (
    <fieldset className="step-wrapper">
      {brands?.map((el, index) => (
        <div
          key={index}
          className={`brand-wrapper ${el.brand_name === bookingData.bookingDetails.brand_name ? 'active' : 'inactive'}`}
          onClick={() => handleChange(el.brand_name)}
        >
          <span>{el.brand_name}</span>
        </div>
      ))}
    </fieldset>
  );
};

export default Brand;
